<template>
  <aside v-if="facets.length" class="sticky top-0 z-40 bg-white shadow-lg">
    <div class="container mx-auto lg:px-20 overflow-auto md:overflow-visible">
      <ul class="flex">
        <li
          v-for="facet in topLevelFacets"
          class="border-l border-l-gray-300 last:border-r last:border-r-gray-300"
        >
          <NavigatorFacetSingle
            v-if="isSingleFacet(facet.id)"
            v-bind="facet"
            :label="getFacetLabel(facet.id)"
          />
          <NavigatorFacet
            v-else
            v-slot="{ showButton }"
            v-bind="facet"
            :label="getFacetLabel(facet.id)"
          >
            <NavigatorFacetSingle
              v-if="facet.id === 'positions' && openPositionsFacet"
              v-slot="term"
              v-bind="openPositionsFacet"
              :label="getFacetLabel(openPositionsFacet.id)"
            >
              <div class="border-t border-t-gray-100 mt-10">
                <NavigatorFacetTerm
                  v-bind="term"
                  class="pt-20"
                  @click="showButton"
                />
              </div>
            </NavigatorFacetSingle>
          </NavigatorFacet>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { SearchFacet } from '~/sites/pharmago/helpers/pharmacies-api/types'

const props = defineProps<{
  facets: SearchFacet[]
}>()

const { getFacetLabel, isSingleFacet } = useNavigator()

// All facets that are rendered in the top level (directly inside the bar).
const topLevelFacets = computed(() =>
  props.facets.filter((v) => v.id !== 'has_open_positions'),
)

// This facet is special, it behaves like a "single facet", but it's rendered
// inside the "positions" facet.
const openPositionsFacet = computed(() =>
  props.facets.find((v) => v.id === 'has_open_positions'),
)
</script>

<style lang="postcss"></style>
