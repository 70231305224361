<template>
  <div
    v-click-away="onClickAway"
    class="dropdown relative"
    :class="visible ? 'dropdown-opened' : ''"
  >
    <slot
      name="toggle"
      :on-click="onClick"
      :close="close"
      :visible="visible"
    ></slot>
    <Transition :name="transitionName">
      <div
        v-if="visible || alwaysRender"
        v-show="alwaysRender ? visible : true"
        ref="container"
        class="dropdown-content absolute top-full bg-white shadow-dropdown z-50 mt-10"
        :class="dropdownClass"
      >
        <slot :close="close" :visible="visible"></slot>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { directive as vClickAway } from 'vue3-click-away'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'

const container = ref<HTMLDivElement | null>(null)
const emit = defineEmits(['close'])

const props = defineProps<{
  dropdownClass?: string
  transitionName?: string
  alwaysRender?: boolean
  disableClickAway?: boolean
}>()

const visible = ref(false)

function onClickAway() {
  if (props.disableClickAway) {
    return
  }
  close()
}

function onClick() {
  visible.value ? close() : open()
}

function close() {
  if (container.value) {
    enableBodyScroll(container.value)
  }
  visible.value = false
  emit('close')
}

function open() {
  visible.value = true

  // Disable body scrolling on mobile.
  if (window.innerWidth < 768) {
    nextTick(() => {
      if (container.value) {
        disableBodyScroll(container.value, {
          reserveScrollBarGap: true,
        })
      }
    })
  }
}
</script>
