<template>
  <div
    class="relative md:box md:bg-white md:box-interactive mobile-only:border-b mobile-only:border-b-gray-300 mobile-only:pb-20 mobile-only:mb-20"
  >
    <NuxtLink :to="url" class="md:flex">
      <div
        class="bg-white relative md:border-r md:border-r-gray-100 w-50 md:w-120 lg:w-200 aspect-square flex items-center justify-center p-5 md:p-20 float-right ml-10 md:ml-0"
      >
        <div class="relative w-full h-full">
          <RokkaImage
            v-if="logoFile"
            :config="imageStyle"
            :hash="logoFile.rokka?.hash"
            :source-width="logoFile.rokka?.sourceWidth"
            :source-height="logoFile.rokka?.sourceHeight"
            :host="rokkaHost"
            class="absolute top-0 left-0 w-full h-full object-contain"
          />
          <div v-else>
            <SpriteSymbol
              name="pharmasuisse-filled"
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/6 h-4/6 object-cover text-gray-100"
            />
          </div>
        </div>
      </div>
      <div class="flex-1 md:px-20 md:pt-20 md:pb-50">
        <h3 class="font-semibold text-md md:text-lg lg:text-xl leading-none">
          <span>{{ displayName || name }}</span>
          <SpriteSymbol
            v-if="isAffiliated"
            name="pharmasuisse"
            :title="$texts('affiliatedPharmacy', 'Angeschlossene Apotheke')"
            class="ml-[0.2em] w-[0.7em] h-[0.7em] inline-block align-baseline text-berry"
          />
        </h3>
        <address v-if="location" class="not-italic md:text-md">
          <span>{{ location.addressLine1 }}</span
          >, <span>{{ location.postalCode }}</span
          >&nbsp;<span>{{ location.locality }}</span>
        </address>

        <div
          v-if="selectedServices?.length"
          class="mt-10 flex flex-wrap gap-10"
        >
          <span
            v-for="service in selectedServices"
            class="text-gray-900 h-20 lg:h-30 px-10 font-medium lg:text-xs uppercase rounded items-center inline-flex bg-gray-100 text-2xs"
          >
            {{ service }}
          </span>
        </div>

        <p v-if="distance">{{ formatDistance(distance) }}</p>
      </div>
    </NuxtLink>
    <div
      class="md:absolute md:bottom-0 md:left-[220px] md:pb-10 mt-10 text-sm md:text-base"
    >
      <ul class="flex gap-20">
        <li v-if="mainUrl" class="hidden md:block">
          <a :href="mainUrl" target="_blank" class="link flex items-center">
            <SpriteSymbol name="external" class="h-[16px] w-30 pr-10" />
            {{ $texts('website', 'Website') }}
          </a>
        </li>
        <li>
          <ClientOnly>
            <PharmacyBusinessHoursStatus
              v-if="openStatus"
              v-bind="openStatus"
            />
          </ClientOnly>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ImageFileFragment } from '#graphql-operations'
import {
  PharmacyDocumentLocation,
  PharmacyDocumentCoordinates,
  PharmacyDocumentOpenStatus,
  ElasticsearchPharmacyDocument,
} from '~/sites/pharmago/helpers/pharmacies-api/types'

const config = useRuntimeConfig()

const props = defineProps<{
  id: string
  name: string
  displayName: string
  description: string
  isAffiliated: boolean
  email: string
  logo?: string
  entityId: string | null
  url: string
  location?: PharmacyDocumentLocation
  coordinates?: PharmacyDocumentCoordinates
  distance?: number
  openStatus?: PharmacyDocumentOpenStatus
  links?: ElasticsearchPharmacyDocument['links']
  selectedServices?: string[]
}>()

const rokkaHost = computed(() => {
  return (config.public.rokkaHost || '').replace('pharmago', 'pharmasuisse')
})

const logoFile = computed<ImageFileFragment | undefined>(() => {
  if (props.logo) {
    return {
      label: props.id + '.png',
      rokka: {
        hash: props.logo,
      },
    }
  }
})

const mainUrl = computed(() => {
  return props.links?.find((v) => v.type === 'website')?.url
})

function formatDistance(distance = 0) {
  if (distance < 1000) {
    return distance + 'm'
  }

  return Math.round(distance / 100) / 10 + 'km'
}

const imageStyle = defineImageStyle({
  type: 'sizes',
  sizes: {
    sm: 40,
    md: 80,
    lg: 160,
  },
})
</script>
