<template>
  <NuxtLink
    :to="buildFacetTermLink(facetId, value, selected)"
    :class="{ 'pointer-events-none opacity-25': !count }"
    class="dropdown-item flex items-center px-20 py-5 cursor-pointer gap-5 hover:text-green-alt text-sm group"
    @click="$emit('click')"
  >
    <div
      class="w-20 h-20 border border-gray-500 shrink-0 flex items-center justify-center group-hover:border-green-alt"
      :class="{ 'bg-green-alt border-transparent': selected }"
    >
      <SpriteSymbol name="check" class="w-15 h-15 text-white" />
    </div>
    <span>{{ label || value }}</span>
    <span class="ml-auto">{{ count }}</span>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { ValidFacetKey } from '~/sites/pharmago/helpers/pharmacies-api/types'

defineProps<{
  facetId: ValidFacetKey
  value: string
  count: number
  label?: string
  selected?: boolean
}>()

defineEmits(['click'])

const { buildFacetTermLink } = useNavigator()
</script>
