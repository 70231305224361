<template>
  <slot
    :facet-id="id"
    :value="term?.value || 'true'"
    :count="count"
    :label="label"
    :selected="selected"
  >
    <NuxtLink
      :to="buildFacetTermLink(id, 'true', selected)"
      class="flex items-center p-15 md:p-20 cursor-pointer gap-10 text-gray-900 hover:text-green-alt font-medium text-sm md:text-base select-none hover:bg-green/5 whitespace-nowrap"
      :class="{ 'pointer-events-none opacity-25': disabled }"
    >
      <div
        class="w-20 h-20 border border-gray-500 shrink-0 flex items-center justify-center"
        :class="{ 'bg-green-alt border-transparent': selected }"
      >
        <SpriteSymbol name="check" class="w-15 h-15 text-white" />
      </div>
      <span>{{ label }}</span>
      <span
        class="font-normal text-gray-700 md:text-right md:min-w-[34px] hidden md:inline-block"
        >{{ count }}</span
      >
    </NuxtLink>
  </slot>
</template>

<script lang="ts" setup>
import {
  SearchFacetTerm,
  ValidFacetKey,
} from '~/sites/pharmago/helpers/pharmacies-api/types'

const { buildFacetTermLink } = useNavigator()

const props = defineProps<{
  id: ValidFacetKey
  label: string
  terms: SearchFacetTerm[]
}>()

// These single filters are boolean based. We are only interested in the one
// that sets the value to true, e.g. "currently open". Makes no sense to
// filter for "currently closed".
const term = computed(() => props.terms.find((v) => v.value === 'true'))
const count = computed(() => term.value?.count || 0)
const selected = computed(() => !!term.value?.selected)
const disabled = computed(() => !count.value)
</script>

<style lang="postcss"></style>
