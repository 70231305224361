<template>
  <div
    class="flex items-center font-medium"
    :class="isOpen ? 'text-green-alt' : 'text-red'"
  >
    <SpriteSymbol name="clock" class="w-20 h-20 mr-2" />
    {{ text }}
  </div>
</template>

<script lang="ts" setup>
const { $texts } = useNuxtApp()

const props = defineProps<{
  isOpen: Boolean
  opensAt?: number
  closesAt?: number
}>()

const text = computed(() => {
  if (props.isOpen) {
    if (props.closesAt) {
      const minutes = Math.round((props.closesAt - Date.now() / 1000) / 60)
      if (minutes < 60 && minutes >= 1) {
        if (minutes === 1) {
          return $texts(
            'openingHoursClosesInOneMinute',
            'Geöffnet, schliesst in 1 Minute',
          )
        }
        return $texts(
          'openingHoursClosesInXMinutes',
          'Geöffnet, schliesst in @minutes Minuten',
        ).replace('@minutes', minutes.toString())
      }
    }

    return $texts('nowOpened', 'Jetzt geöffnet')
  }

  if (props.opensAt) {
    const minutes = Math.round((props.opensAt - Date.now() / 1000) / 60)
    if (minutes < 60 && minutes >= 1) {
      if (minutes === 1) {
        return $texts('openingHoursOpensInOneMinute', 'Öffnet in 1 Minute')
      }
      return $texts(
        'openingHoursOpensInXMinutes',
        'Öffnet in @minutes Minuten',
      ).replace('@minutes', minutes.toString())
    }
  }

  return $texts('nowClosed', 'Momentan geschlossen')
})
</script>
