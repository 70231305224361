<template>
  <NuxtLink
    v-if="match"
    :to="match.url"
    class="box p-10 md:p-20 text-blue bg-cyan-lighter md:flex items-center gap-20 justify-between relative overflow-hidden"
  >
    <div
      class="aspect-square md:absolute md:top-10 h-60 md:h-auto md:bottom-10 right-0 md:opacity-40 text-blue-light md:text-blue-lighter float-right"
    >
      <MediaIcon v-if="match.icon" v-bind="match.icon" class="w-full h-full" />
      <SpriteSymbol v-else name="individual_member" class="w-full h-full" />
    </div>
    <div class="relative z-10 lg:pr-60">
      <p class="text-xs md:text-sm uppercase font-medium">
        {{ $texts('navigator.contentTeaserTitle', 'Mehr zum Thema') }}
      </p>
      <h3 class="text-lg md:text-xl font-semibold">{{ match.title }}</h3>
      <div class="text-sm lg:text-base mt-10" v-html="match.description" />
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { MediaIconFragment, ServiceInfoFragment } from '#graphql-operations'
import { filterByTypenames } from '~/helpers/type'

const language = useCurrentLanguage()

const props = defineProps<{
  serviceIds: string[]
  text: string
}>()

type ServiceInfoItem = {
  serviceId: string
  title: string
  description: string
  searchText: string
  url: string
  icon?: MediaIconFragment
}

const { data } = await useLazyAsyncData<ServiceInfoFragment[]>(
  'getServiceInfos',
  () =>
    useCachedGraphqlQuery('getServiceInfos').then((v) =>
      filterByTypenames(v.data.query.items, ['PhsPortfolioTypeCategory']),
    ),
)

const items = computed<ServiceInfoItem[]>(
  () =>
    data.value?.map((v) => {
      const title = v.label || ''
      const description = v.description || ''
      return {
        serviceId: v.id || '',
        title,
        description,
        searchText: (title + ' ' + description).toLowerCase(),
        url: `/${language.value}/node/${v.remoteNodeId}`,
        icon: v.icon,
      }
    }) || [],
)

const match = computed(() => {
  // Try to find an exact match for one of the selected services.
  const matchingId = props.serviceIds.find((id) =>
    items.value.find((v) => v.serviceId === id),
  )
  if (matchingId) {
    return items.value.find((v) => v.serviceId === matchingId)
  }

  // Try to find a match using a simple "string contains" search.
  if (props.text.length > 3) {
    return items.value.find((v) => v.searchText.includes(props.text))
  }
})
</script>
