<template>
  <div
    v-if="hits.length"
    class="p-10 md:p-15 bg-gray-100 border border-gray-300"
  >
    <div>
      <SpriteSymbol
        name="search"
        class="w-40 h-40 float-right text-green-alt"
      />
      <div class="text-xs uppercase font-medium leading-none">
        {{ $texts('navigator.fulltextTeaserSubtitle', 'Passende Inhalte') }}
      </div>
      <h3 class="text-md md:text-lg !leading-none font-semibold">{{ text }}</h3>
      <ul class="text-sm grid gap-5 mt-20">
        <li v-for="hit in hits">
          <NuxtLink class="link flex" :to="hit.source?.url">
            <SpriteSymbol
              name="arrow"
              class="h-10 w-15 mr-5 inline-block mt-[0.3em]"
            />
            <span>{{ hit.source?.title }}</span>
          </NuxtLink>
        </li>
      </ul>

      <NuxtLink
        v-if="total > 1"
        :to="{ name: 'search', query: { text } }"
        class="button is-primary is-narrow mt-30"
      >
        {{ $texts('navigator.searchMoreContent', 'Weitere Inhalte suchen') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string
}>()

const { data } = useLazyAsyncData(
  () =>
    useCachedGraphqlQuery('navigatorFulltextTeaser', {
      text: props.text,
    }).then((v) => v.data.searchContent),
  {
    watch: [props],
  },
)

const total = computed(() => data.value?.total || 0)
const hits = computed(() => (data.value?.hits || []).slice(0, 3))
</script>
